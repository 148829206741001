import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-field-limit-counter-wrapper',
  templateUrl: './text-field-limit-counter-wrapper.component.html',
  styleUrls: ['./text-field-limit-counter-wrapper.component.scss'],
})
export class TextFieldLimitCounterWrapperComponent {
  @Input() inputText = '';
  @Input() limit: number;
  @Input() isFocused = true;
  @Input() hideLimitCounterIfNotFocused = false;
}
