import { Inject, Injectable } from '@angular/core';
import { CONTEXT_SERVICE, ContextService } from './context/context.model';
import { NavControllerService } from './nav-controller.service';
import { ActionSource } from './yeti-protocol/tracking';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppNavController {

  constructor(
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private navController: NavControllerService,
    private router: Router
  ) { }

  openPublicUserProfile(userId: string, source: ActionSource = ActionSource.unspecified, contextHomePath?: string): void {
    let url = `${contextHomePath ?? this.contextService.currentContext.homePath}/public/profile/${userId}`;
    if (source && source !== ActionSource.unspecified) {
      url += '?source=' + source;
    }
    this._forward(url);
  }

  openProfile(source: ActionSource = ActionSource.unspecified, contextHomePath?: string): void {
    let url = `${contextHomePath ?? this.contextService.currentContext.homePath}/profile`;
    if (source && source !== ActionSource.unspecified) {
      url += '?source=' + source;
    }
    this._forward(url);
  }

  openJournal(journalId: string): void {
    const url = `${this.contextService.currentContext.homePath}/journal-details/${journalId}`;
    this._forward(url);
  }

  openMessenger(connectionId?: string, source?: string): void {

    this._forward(`${this.contextService.currentContext.key}/contacts`);

    setTimeout(() => {
      let url = connectionId ?
        `${this.contextService.currentContext.key}/contacts?tab=my-chats&chatId=${connectionId}` :
        `${this.contextService.currentContext.key}/contacts?tab=my-chats`;

      if (source) {
        url += '&source=' + source;
      }

      this.router.navigateByUrl(url, { replaceUrl: true });
    }, 100);
  }

  openGroup(groupId: string, source?: string): void {
    let url = `${this.contextService.currentContext.homePath}/group/${groupId}`;
    if (source) {
      url += '?source=' + source;
    }
    this._forward(url);
  }

  openGroups(): void {
    const url = `${this.contextService.currentContext.homePath}/groups`;
    this._forward(url);
  }

  openGroupCase(caseId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService.currentContext.homePath}/group/case/${caseId}`;

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openEventCase(caseId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService.currentContext.homePath}/event/case/${caseId}`;

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openPrivatePublicSharedCase(postId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = '/' + [this.contextService.currentContext.key, 'shared/case', postId].join('/');

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openGroupPost(postId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService.currentContext.homePath}/group/post/${postId}`;

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openEventPost(postId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService.currentContext.homePath}/event/post/${postId}`;

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openPrivatePublicPost(postId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = '/' + [this.contextService.currentContext.key, 'post', postId].join('/');

    if (source) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openArticle(articleId: string, source?: ActionSource, triggerPoint: string = null, campaign?: string): void {
    let url = `${this.contextService.currentContext.homePath}/article/${articleId}`;
    const queryParams: Array<string> = [];
    if (source) {
      queryParams.push(`source=${source}`)
    }
    if (triggerPoint) {
      queryParams.push(`triggerPoint=${triggerPoint}`)
    }
    if (campaign) {
      queryParams.push(`campaign=${campaign}`)
    }
    if (queryParams.length > 0) {
      url = [url, queryParams.join('&')].join('?');
    }
    this._forward(url);
  }

  openExternallySharedCase(caseId: string): void {
    const url = `${this.contextService.currentContext.key}/wrapper/joinmyao/case/${caseId}`;
    this._forward(url);
  }

  openEvents(source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService?.currentContext?.homePath}/events`;

    if (source && source !== ActionSource.unspecified) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openEvent(eventId: string, source: ActionSource = ActionSource.unspecified): void {

    let url = `${this.contextService?.currentContext?.homePath}/event/${eventId}`;

    if (source && source !== ActionSource.unspecified) {
      url += '?source=' + source;
    }

    this._forward(url);
  }

  openFeedPage(): void {
    const url = `${this.contextService?.currentContext?.homePath}/feed`;
    this._forward(url);
  }

  _forward(url: string): void {
    this.navController.navigateForward(url);
  }
}
