// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-field-limit-counter-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}
.text-field-limit-counter-wrapper .add-min-height {
  min-height: 50px;
}
.text-field-limit-counter-wrapper .limit-counter {
  height: 20px;
  text-align: right;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
